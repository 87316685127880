const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
};

let callback = (entries) => {
    entries.forEach(entry => {
        if(entry.isIntersecting) {
            entry.target.classList.add('is-visible');
        } else {
            // entry.target.classList.remove('is-visible');
        }

    });
};
let observer = new IntersectionObserver(callback, options);
document.querySelectorAll('[data-animate]').forEach(box => { observer.observe(box) });
